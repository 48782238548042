
import { Options, Vue } from "vue-property-decorator";
import { parseTime } from "@/utils";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  date: any = "";
  private show = false;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private tableData: any[] = [];
  private searchForm = {
    dirId: "",
    deviceTime: "",
    deviceNo: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
  };

  mounted() {
    this.searchForm.deviceNo = this.$route.query.id as any;
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
  }
  currentDate() {
    return parseTime(this.date, "{m}月{d}日");
  }
  fConfirmDate(d: Date) {
    this.date = parseTime(d, "{y}-{m}-{d}");
    this.searchForm.deviceTime = this.date;
    this.show = false;
    this.refreshing = true;
    this.onRefresh();
  }
  openPopup() {
    this.show = true;
  }
  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.tableData = [];
        this.searchForm.pageNum = 1;
        this.refreshing = false;
      }
      api.pageElevatorRecord(this.searchForm).then((res: any) => {
        this.tableData = [...this.tableData, ...res.data.records];
        this.searchForm.pageNum++;
        this.loading = false;
        if (this.tableData.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }
}
